<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        奥飞特七中国游戏隐私政策
      </h1>

      <div class="text notitle">
        <p>(最后更新于2024年12月10日)</p>

        <h2>关于我们和本隐私政策</h2>

        <p>您的隐私对我们非常重要，我们将始终遵循本隐私政策（"隐私政策"）中所述目的使用您的个人信息。本隐私政策中，"奥飞特七"、"我们 "或 "我们的"指的是奥飞特七网络科技有限公司、绍兴欧飞特七网络科技有限公司、上海励挚网络科技有限公司以及其关联公司，例如奥飞特七有限公司（Outfit7 Limited）。</p>

        <p>
          <strong>本隐私政策是
            <InternalLink
              route="/pipl/eula"
              text="《最终用户许可协议》"
            />（“用户协议”）的组成部分，</strong>适用于所有终端设备（包括但不限于移动设备）上提供的奥飞特七应用程序（"游戏"），且在您使用游戏时产生效力。<strong>如您未满14周岁，还将同时适用
            <InternalLink
              route="/pipl/children-privacy"
              text="《儿童隐私政策》。"
            /></strong>
        </p>

        <p><strong>在使用我们的游戏前，请您务必仔细阅读并充分理解本政策，特别是以粗体标识的条款，您应重点阅读。您应在充分理解并同意本政策后再开始使用我们的游戏。一旦您开始使用我们的游戏，即表示您已充分理解并同意本政策。</strong></p>

        <p>下文将帮助您详细了解我们是如何收集、使用、分享、保护、传输、储存个人信息；并帮助您了解查询、访问、删除、更正、撤回授权个人信息的具体方式。<strong>有关您个人信息权益的重要内容我们已用加粗形式提示，请特别关注。</strong></p>

        <p>
          <a
            class="link"
            href="#1"
          >我们如何收集和使用您的个人信息</a>
        </p>
        <p>
          <a
            class="link"
            href="#2"
          >我们如何分享您的个人信息</a>
        </p>
        <p>
          <a
            class="link"
            href="#3"
          >第三方条款及情况</a>
        </p>
        <p>
          <a
            class="link"
            href="#4"
          >未成年人保护</a>
        </p>
        <p>
          <a
            class="link"
            href="#5"
          >您的权利</a>
        </p>
        <p>
          <a
            class="link"
            href="#6"
          >个人信息的安全保护</a>
        </p>
        <p>
          <a
            class="link"
            href="#7"
          >个人信息的跨境传输</a>
        </p>
        <p>
          <a
            class="link"
            href="#8"
          >信息的储存和删除</a>
        </p>
        <p>
          <a
            class="link"
            href="#9"
          >隐私政策的更新</a>
        </p>
        <p>
          <a
            class="link"
            href="#10"
          >联系我们</a>
        </p>

        <h2 id="1">
          我们如何收集和使用您的个人信息
        </h2>

        <p><strong>您无需注册账号即可使用我们的游戏服务，我们不会要求您提供真实姓名、手机号、邮箱或地址用于账号注册。</strong>我们仅会在以下情况通过您或您的设备收集和获取必要的信息：</p>

        <ul>
          <li>
            当您使用我们的游戏服务时，或；
          </li>
          <li>
            当您在游戏中与我们的服务商互动时，或；
          </li>
          <li>
            当您在其他应用程序中与关于我们的广告互动时。
          </li>
        </ul>

        <p>在征得您的同意后，我们将出于以下目的，收集和使用您的个人信息：</p>

        <p><strong>1.	帮助您开始游戏</strong></p>

        <p>根据法律法规及相关主管部门的要求，在开始游戏前您需要完成实名认证。我们需要在游戏中接入国家指定的实名认证系统，以便实名认证机构能够核验您的真实身份。<strong>您需要提供的个人信息包括完整的姓名和身份证号，这些身份信息是敏感个人信息。您理解我们不会读取或储存上述敏感信息，上述身份信息将由实名认证机构通过集成在游戏中的接口（API）直接收集并核验。实名认证机构仅会告知我们核验是否通过以及您的年龄区间，以便我们判断是否应当将您纳入国家要求的防沉迷系统中。</strong>如您拒绝提供真实的身份信息或提供了虚假的身份信息，您将无法通过实名认证，我们也将无法向您提供游戏服务。</p>

        <p><strong>2.	为您提供基础游戏功能</strong></p>

        <ul>
          <li>
            为了提供更好的游戏服务，我们将随机为您生成一个用户识别号（“UID”）。该UID仅限我们内部使用，属于<strong>非识别性个人信息</strong>，其无法用于识别或确认您的真实个人身份。
          </li>
          <li>
            为向您提供正常的游戏功能并提高游戏的适配性，我们会收集您的UID、游戏进度、内购情况、网络状态、<strong>IP地址、设备标识符（IDFA）、</strong>语言、地区设置、浏览器插件类型及版本、用户代理字符串（UA）、商店/平台、SDK版本、时间戳、设备技术信息（设备型号和名称、操作系统名称和版本、CPU信息、内存大小、屏幕尺寸、硬件、软件、电话运营商、互联网服务提供商、应用的API密钥标识符、夜间模式、音量、字体大小、电源模式、音量、传感器信息））。<strong>您理解IP地址及设备标识符（IDFA）是典型的个人信息，但我们不会也无法将IP地址用于识别您的精准地理位置（仅识别国家或城市）；而上述其他设备技术信息均为非识别性个人信息，无法用于识别或确认您的真实个人身份。</strong>
          </li>
          <li>
            为监控和修复可能存在的程序崩溃和错误，确保游戏的正常运行，我们会收集和使用您的UID及<strong>IP地址。</strong>为预防恶意程序和维护游戏的正常运行，我们会在您使用游戏期间收集游戏的整体运行情况、使用频率、崩溃和错误、UID、<strong>IP地址、</strong> 安装情况以及性能数据。您理解除IP地址外，上述信息为游戏行为信息，且属于非识别性个人信息。
          </li>
          <li>
            如您选择登录并使用我们合作伙伴的游戏中心，我们将收集和使用您的玩家标识符。通过与此类游戏中心账号相关联，您将能够参与排行榜、恢复游戏进度、在多个设备上登录并同步进度。如您选择参与游戏排行榜，您还将与游戏中心及其他游戏玩家分享您的分数、排名和成就等信息。<strong>您理解游戏中心由我们的合作伙伴运营，受其各自隐私政策、条款和最终用户协议的约束，我们对此不承担责任，并且我们无法控制这些游戏中心的信息共享情况或政策。</strong>
          </li>
          <li>
            为向您展示包含3D形象的游戏内容并保证基础动画功能正常运行，我们可能需要使用第三方服务商提供的引擎产品或服务。<strong>您理解3D引擎为提供服务而收集的设备信息仅会在您设备上被处理和使用，不会被传输给该第三方服务商。</strong>
          </li>
        </ul>

        <p><strong>3.	为您提供内容分享、信息推送、营销推广和反馈调研服务</strong></p>

        <ul>
          <li>
            为了您能够使用分享功能，将某些游戏内容分享到第三方产品或服务上，我们可能需要访问您的剪切板，并在游戏中集成第三方服务商的软件开发工具包（SDK）。<strong>您理解剪切板信息仅会在您的设备上被处理，我们不会存储您的剪切板信息，也不会收集您剪切板中的其他无关信息。您可以查阅我们的
              <InternalLink
                route="/pipl/third-share-list"
                text="《第三方信息共享清单》"
              />以了解更多细节
            </strong>
          </li>
          <li>
            为及时向您提供游戏及服务动态，我们会通过您设备的系统通知功能向您推送游戏内容、活动通知及进展。为了实现即时消息推送，我们在游戏中集成了第三方服务商的消息推送SDK。为了保障消息推送的及时性，游戏将在后台保活，如您不想收到此类提醒，可以在您设备的设置功能中选择关闭消息推送功能。为保障游戏内消息推送的稳定性、触达率，及时性，我们可能会进行必要的自启动或关联启动其他应用的行为。
          </li>
          <li>
            为评估游戏的营销活动效果、优化推广内容、跟踪和分析数据并防止数据欺诈，我们及我们的营销推广服务商将收集您的设备数据和信息，例如您在其他应用程序中与我们的游戏广告互动时的广告展示频次、点击次数、安装次数或其他营销活动指标。
          </li>
          <li>
            为进一步改进和提升游戏服务质量，我们将不定期在游戏内发布问卷或调查。您可以自由选择是否参与此类问卷或调查，我们仅会收集您主动提供给我们的个人信息。<strong>您理解该等个人信息将经过匿名化处理后进行汇总和使用。</strong>
          </li>
          <li>
            为向您推广我们的其他游戏，我们将收集您设备上已安装的奥飞特七游戏列表。<strong>您理解我们并不会收集您设备中其他应用程序的安装情况。</strong>
          </li>
        </ul>

        <p><strong>4.	帮助您进行内购及处理退款</strong></p>
        <ul>
          <li>
            您可以通过体验游戏或商店购买来获得我们游戏中的虚拟物品（例如游戏币、游戏道具、角色服装等）及订阅。<strong>您理解游戏内商店由我们运营，但收款服务并非由我们直接提供。您支付的款项将直接由应用商店或第三方支付服务提供商（支付宝、微信支付）收取。他们可能会收集您的姓名、银行卡类型、银行卡卡号、有效期及手机号。您理解上述信息多为个人敏感信息，但这些信息是支付功能所必需的个人信息，拒绝提供将导致您无法使用支付功能。我们不会获得与您支付有关的任何敏感细节。</strong>在您完成支付后，合作方仅会告知我们支付是否成功，以便我们判断是否向您提供指定的虚拟物品。如您希望要了解更多细节，请参考您选择的应用商店或第三方支付服务提供商的隐私政策。
          </li>
          <li>
            为帮助您处理退款事宜，我们的客户服务提供商可能会要求您提供必要的个人信息，例如您的身份证号码、银行账号和承诺函，以验证您的真实身份并向您支付相关退款。<strong>您理解身份证号码和银行账号属于个人敏感信息，但这些信息将仅用于处理退款并由我们的客户服务提供商在中国境内进行使用和存储。</strong>服务商仅会告知部分必要的非识别性个人信息以便我们进行内部分析，例如游戏名称、您的UID，充值时间、充值金额及退款金额。
          </li>
        </ul>

        <p><strong>5.	为您提供客户服务</strong></p>

        <p>当您通过游戏内置的支持功能寻求客服帮助时，我们的客服系统会自动获得您的UID作为基本信息。客服人员将使用上述信息来处理您的需求，并为您提供相应的帮助和支持。如客户服务需要您的更多信息，我们将另行征得您的同意，并仅在您授权的范围内使用此类信息。</p>

        <p><strong>6.	为您提供个性化广告</strong></p>

        <p>
          我们将在游戏中为您展示广告，其中可能包括基于您兴趣和在线习惯的个性化广告。为展示个性化广告，我们会在游戏中使用第三方服务商的SDK。上述第三方可能对您进行跨网站和跨应用程序的追踪，分析您的偏好、习惯和位置，以便为您提供相关度更高的广告内容。<strong>第三方服务商基于此目的可能收集的个人信息包括但不限于您的设备技术信息（设备型号,设备名称,操作系统名称,版本,设备设置,设备序列号等）、设备标识符<span style="overflow-wrap:break-word;color: #000;text-decoration: none;">（Android ID,IMEI,IMSI,OAID,IDFA,IDFV,VAID,UDID,AAID,ICCID,FUID,EMUI,Emmcid）</span>、应用安装列表、MAC地址、Wi-Fi信息（SSID,BSSID,信号强度,连接情况）、传感器信息（陀螺仪,加速度传感器,重力传感器）、IP地址（国家,城市）、广告数据（广告互动,展示,点击,转化广告数据）、网络信息（WiFi状态,电信运营商,网络类型,网络连接状态,网络环境）、软件信息（软件版本号,软件签名,软件包名,软件来源）、设备SN码和储存权限等。</strong>
        </p>

        <p>
          <strong>上述第三方服务商为向您展示广告内容，会通过集成在我们游戏内的SDK收集您的个人信息，这些行为受到第三方服务商各自的隐私政策约束。</strong>我们将尽商业上的合理努力要求第三方服务商在处理您的个人信息时遵守相关法律法规，要求其采取必要的保密和安全措施，但我们无法保证其将严格按照我们的要求采取相应措施。您可以查阅我们的
          <InternalLink
            route="/pipl/third-share-list"
            text="《第三方信息共享清单》"
          />以了解更多细节。
        </p>

        <p>您可以随时通过以下方法关闭（退出）个性化广告：</p>

        <ul>
          <li>
            您可以在您设备的设置中找到"个性化推荐/跟踪/智能广告"类似的选项，并选择打开或关闭个性化广告；或
          </li>
          <li>
            您可以在我们的部分游戏中打开和关闭个性化广告，访问"信息界面"、"设置 "或勾选"禁用基于兴趣的广告"来实现此目的；或
          </li>
          <li>
            您可以与我们合作的第三方广告服务商单独联系，要求该服务商停止向您展示个性化广告。您可以查阅<InternalLink
              route="/pipl/third-share-list"
              text="《第三方信息共享清单》"
            />上的信息与他们联系，并提出您的相应诉求。
          </li>
        </ul>

        <p>如您决定停止接收个性化广告，我们将不再向您展示个性化广告，此举不会影响您体验游戏的其他功能；关闭个性化广告后，您将不再接收到个性化广告，但您仍然会看到广告，广告的数量不会变化，但广告的相关度会降低。</p>






        <!-- from here!!! -->
        <p><strong>7.	请求访问您的设备功能</strong></p>

        <p>我们的部分游戏提供由虚拟角色重复您讲话的功能。<strong>在访问您设备的麦克风功能前，我们会征得您的单独同意。</strong>如您允许游戏使用该功能，虚拟角色将能够重复您所说的话。<strong>您理解我们的游戏不会进行音频记录，您对虚拟角色叙述的内容将仅用于实时重复，并不会实际储存在我们的服务器中。</strong>如您拒绝我们访问设备上的麦克风，虚拟角色将不能进行重述，但您仍可以正常体验游戏的其他功能。</p>

        <p><strong>8.	例外情况</strong></p>

        <p>根据相关法律法规规定，在以下情形中我们处理您的个人信息前无需征得您的授权同意：</p>

        <ul>
          <li>为订立、履行您要求的合同所必需；</li>
          <li>为履行法定职责或者法定义务所必需；</li>
          <li>为应对突发公共卫生事件，或者紧急情况下为保护您或他人的生命健康和财产安全所必需；</li>
          <li>为公共利益实施新闻报道、舆论监督等行为，在合理范围内处理您的个人信息；</li>
          <li>在合理范围内处理您自行公开或者其他已经合法公开的个人信息；</li>
          <li>法律、行政法规规定的其他情形。</li>
        </ul>

        <h2 id="2">
          我们如何分享您的个人信息
        </h2>

        <p>我们无法单独提供游戏成功运营所需的全部服务或功能。因此，我们会与其他公司合作，以便其为游戏提供某些特定的服务或功能。<strong>您理解我们在与其他公司共享您的个人信息前将征得您的同意。</strong></p>

        <p><strong>1.	分享</strong></p>

        <p>我们非常重视保护您的个人信息及隐私。我们仅在合法、正当、必要、特定、明确的目的下向第三方提供您的个人信息。对于接收个人信息的公司，我们会与其签署严格的保密协定，要求他们按照我们的要求、其他任何相关的保密及安全措施来处理信息。</p>

        <p>为了实现本隐私政策所述目的，我们的游戏会接入第三方服务商的软件开发工具包（SDK）或其他类似技术，以便向您提供更好的游戏体验。我们委托第三方处理或与第三方分享您个人信息的情况如下：</p>

        <ul>
          <li><strong>云服务器提供商</strong>：我们需要借助第三方提供商提供的云储存服务器及代理服务器，将从您那里收集到的个人信息进行存储及传输；</li>
          <li><strong>崩溃报告提供商</strong>：我们需要第三方服务商帮助记录和监测游戏运行时的程序错误和崩溃，以便我们之后对游戏程序进行修复和更新；</li>
          <li><strong>内置客服支持工具</strong>：借助第三方服务商提供的应用内客户支持系统，我们能够确保您在游戏内及时联系到我们；</li>
          <li><strong>支付工具提供商：</strong>：我们需要第三方服务商提供支付工具，以帮助用户完成安全的应用内付款，并通知我们付款成功与否的结果；</li>
          <li><strong>广告提供商</strong>：以便我们在游戏中为您提供个性化广告服务；</li>
          <li><strong>分析提供商</strong>：通过第三方服务商的技术，帮助我们记录和分析营销广告的展示频次、下载量和流量来源，帮助我们了解用户从应用商店查看或下载我们游戏的情况；</li>
          <li><strong>信息推送提供商</strong>：帮助我们发送即时消息，以便用户能够了解游戏和我们服务的最新动态；</li>
          <li><strong>归因和防欺诈提供商</strong>：帮助我们衡量广告效果、优化营销活动以及防止营销数据造假欺诈（即归因欺诈，例如使用网络机器人、恶意脚本等技术虚增广告的点击量）；</li>
        </ul>

        <p>
          <strong>您理解上述第三方SDK或类似的技术会直接收集您的个人信息。当您在我们的游戏中使用相关服务时，您同意相关第三方能通过接入我们游戏的SDK或类似技术直接收集和处理您的个人信息。</strong>我们会评估第三方服务商收集个人信息的合法性、正当性和必要性，要求他们对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。您可以查阅
          <InternalLink
            route="/pipl/third-share-list"
            text="《第三方信息共享清单》"
          /> 以了解我们在游戏中使用的第三方服务商的更多细节。
        </p>


        <p><strong>2.	转移</strong></p>

        <p>在我们发生合并、收购、资产转让、分立、解散、被宣告破产或类似情况时，如您的个人信息需要转移，我们会将相关情况告知您，向您告知接收方的名称和联系方式，并要求新的接收方按照法律、行政法规及不低于本政策的要求继续保护您的个人信息。</p>

        <p>
          <strong>3.	公开披露</strong>
        </p><p>我们仅会在以下情况公开披露您的个人信息：</p>
        <ul>
          <li>
            在获得您的明确同意后；
          </li>
          <li>
            根据法律、行政法规的要求。
          </li>
        </ul>

        <p><strong>4.	例外情况</strong></p>

        <p>
          您理解在某些情况下，我们会根据相关法律法规，在无需征得您授权同意的情况下分享、转让或公开披露您的个人信息。具体请参考"我们如何收集和使用您的个人信息"的
          "例外情况"章节。
        </p>

        <h2 id="3">
          第三方条款及情况
        </h2>

        <p>我们的游戏可能包含第三方网站、产品或服务的链接。请注意上述网站、产品或服务并非由我们或由我们控制的人或公司开发及管理。我们无法控制这些人或公司的行为、他们的网站、产品或服务的内容、他们使用个人信息的场景、或他们提供的任何产品或服务。我们向您展示第三方的相关链接并不表明我们与这些公司或个人之间存在赞助或隶属关系，也并不等同于我们对相关第三方的隐私政策、信息安全及合法情况的表示认可。<strong>上述第三方在向您收集的个人信息（包括地理位置或联系方式等）时适用其自己的隐私政策，我们鼓励您了解相关第三方的隐私政策。</strong></p>

        <h2 id="4">
          未成年人保护
        </h2>

        <p>我们的游戏是全年龄段玩家的欢乐来源，我们始终如一地致力于提供一个安全的游戏环境以便所有玩家都能享受其中。我们高度重视保护未成年人的个人信息安全，积极按照国家防沉迷政策的相关要求，通过启用防沉迷系统保护未成年人的合法权益。<strong>我们会通过实名认证系统校验并判断您是否为未成年人，进而决定是否将您纳入到防沉迷系统当中。但您理解我们不会收集任何能够识别到儿童个人或与儿童相关联的个人信息</strong>。</p>

        <p>我们将根据相关法律法规保护未成年人的个人信息。<strong>如我们发现在未事先获得可证实的父母或其他监护人同意的情况下，收集了未成年人的个人信息，我们将设法尽快删除上述信息。</strong></p>

        <ul>
          <li>如您是未满18周岁的未成年人，在开始游戏前，需在您父母或其他监护人的监护、指导下阅读并同意本隐私政策；</li>
          <li>如您是未成年人的监护人，请确认您所监护的未成年人在开始游戏前或提供个人信息前是否已获得您的授权同意。如您对您所监护的未成年人所提供的个人信息有任何疑问，请及时与我们联系；</li>
          <li>
            <strong>特别地，如您是未满14周岁的未成年人，您将同时适用本隐私政策及我们的
              <InternalLink
                route="/pipl/children-privacy"
                text="《儿童隐私政策》"
              />。只有在取得监护人对
              <InternalLink
                route="/pipl/children-privacy"
                text="《儿童隐私政策》"
              />的同意后，您方可使用我们的游戏服务。</strong>
          </li>
        </ul>

        <p><strong>作为未成年人的监护人，您应当指导未成年人安全合理使用网络，关注未成年人上网情况以及相关生理状况、心理状况、行为习惯，防范未成年人接触危害或者可能影响其身心健康的网络信息，合理安排未成年人使用网络的时间，预防和干预未成年人沉迷网络。</strong></p>

        <h2 id="5">
          您的权利
        </h2>

        <p>您在管理个人信息及我们如何处理这些信息方面拥有合法的权利。<strong>您理解该等权利将受到一些例外情况的限制，并且我们在协助您行使该等权利前，有权要求您提供合理的证明材料以核实您的身份。</strong>您可以随时联系我们来行使以下个人信息相关的权利。</p>

        <p><strong>1.	变更您对本隐私政策的授权同意</strong></p>
        <ul>
          <li>
            针对您授权同意我们处理个人信息的行为，您有权随时进行变更。您可以随时通过卸载各台设备上我们的游戏来撤销授权同意，并终止我们以及与我们有合作关系的其他公司对您个人信息的收集行为。
          </li>
        </ul>


        <p><strong>2.	查询、复制、更正、补充、转移和删除您的个人信息</strong></p>
        <ul>
          <li><strong>查询权和复制权。</strong>您有权了解我们持有您的哪些信息，并要求我们将该等信息传输给您，特殊情况除外。您可以通过与我们联系，告知您希望行使查询权和复制权，以及您想收到哪些信息来行使上述权利。您理解我们可能无法完整的提供您要求的全部信息，例如该等信息还包括其他人的个人信息。如我们无法向您提供您要求的个人信息，我们会在回复中向您解释具体原因。</li>
          <li><strong>更正权和补充权。</strong>我们将尽可能保证所掌握的个人信息的准确性和及时性。如您发现我们所掌握的与您相关的任何个人信息有误，请及时联系我们，我们将尽快更正有误的相关信息。</li>
          <li><strong>转移权。</strong>在部分情况下，您有权要求我们提供您已分享给我们的个人信息，以便您可以将这些信息转移给另一方。如您希望行使转移权，请联系我们并注明需转移的信息内容及接收方。</li>
          <li><strong>删除权。</strong>您有权要求我们删除目前持有的与您相关的个人信息。如您希望行使删除权，请联系我们并注明需删除的内容。当我们校验了必要信息（例如UID）且识别到您后，我们将尽快删除您要求删除的个人信息。但您理解我们仍可能根据法律、行政法规的规定以匿名的形式保留部分或全部上述信息。此外，您还随时可以通过卸载各台设备上我们的全部游戏，阻止我们进一步处理和收集您的个人信息。</li>
        </ul>

        <p><strong>3. 账号注销</strong></p>
        <p><strong>我们的游戏无需账号注册，但我们在部分游戏中仍根据法律法规及应用商店的规定为您提供相似的功能。</strong>您可以在部分游戏的设置中点击“账号注销”选项，并根据指引进行操作。在您点击此选项后，您的游戏进度、虚拟物品等将被删除。您也可以通过“联系我们”章节所列的反馈渠道联系我们，我们将尽快为您做出解答。<strong>相关信息一旦被删除将无法恢复，请您谨慎操作。</strong></p>


        <h2 id="6">
          个人信息的安全保护
        </h2>

        <p><strong>1.	安全保护措施</strong></p>

        <p>为了保障您的个人信息安全，我们采取了符合业界标准、合理可行的物理、电子和管理方面的安全措施，进而保护您的个人信息不会被泄露、毁损或丢失，包括但不限于在您的移动设备与我们的游戏进行数据交换时采用TSL加密保护、使用加密技术保障数据的安全性采用受信赖的保护机制防止数据免遭恶意攻击等。我们对可能接触到您个人信息的员工采取了严格的管理，包括但不限于根据其岗位的不同采取不同的权限管理、同其签署保密协议、监控其操作情况等。尽管我们已尽商业上的合理努力，保障持有您个人信息期间的隐私数据安全，但您理解并不存在完美或牢不可破的安全保护措施。</p>

        <p><strong>2.	安全事件的处置措施</strong></p>

        <p>如发生个人信息泄露等安全事件，我们将启动应急预案，阻止安全事件进一步扩大。安全事件发生后，我们将根据法律法规的要求，向您告知安全事件的基本情况和可能造成的影响、我们已采取或将要采取的处置措施、您可自主防范或降低风险的建议以及对您的补救措施。我们将通过合理有效的方式发布公告向您告知相关情况。同时，我们还将按照相关监管部门的要求，上报个人信息安全事件的处置情况。</p>

        <h2 id="7">
          个人信息的跨境传输
        </h2>

        <p>我们及我们的游戏在全球范围内运营，且游戏全部或部分地由我们在境外的关联公司及/或其他第三方服务商开发或提供。您理解并同意，有关您个人信息的数据跨境传输是为了订立、履行个人作为一方当事人的合同所必需的。以监测营销广告效果、优化营销活动及预防数据造假（归隐欺诈）为目的，您的个人信息可能会被传输给我们的关联公司，例如奥飞特七有限公司（Outfit7 Limited），并从中国跨境传输到世界其他国家或司法管辖区；以提供营销广告效果监测及归因服务、监测程序崩溃为目的，我们的第三方服务商可能将您的个人信息从中国跨境传输到世界其他国家或司法管辖区。请注意，这些国家和司法管辖区的数据保护法可能与中国不同。但无论您的个人信息被我们传输或储存到何处，我们都将采取合理及/或法律要求的措施，遵循本隐私政策和相关法律法规的要求保护您的个人信息。</p>

        <h2 id="8">
          信息的储存和删除
        </h2>

        <p>我们会以安全的方式，将在中华人民共和国境内收集的您的个人信息优先存储在中华人民共和国境内。一般情况下，我们只会在为实现本隐私政策所述目的所必需的最短时间内储存您的个人信息。超出最短必要期限的，我们将使用合理的措施对您的个人信息进行删除或匿名化处理，以保护您的个人信息免遭未经授权的访问或使用。但相关法律法规或监管机构要求或允许的更长储存时间除外。</p>

        <h2 id="9">
          隐私政策的更新
        </h2>

        <p>为了向您提供最新的资讯、应对法律和技术方面的变化以及最大限度地保护游戏用户，我们将在必要时对本隐私政策进行修订。我们对您个人信息的处理适用最新版的隐私政策，您可以在此了解我们最新版本的隐私政策。</p>

        <p>本隐私政策更新后，我们将在游戏中发出更新后的版本并通过弹窗提示您，以便您及时了解本隐私政策的最新情况。如您需要先前版本的隐私政策，请与我们联系并附上您的具体需求。</p>

        <h2 id="10">
          联系我们
        </h2>

        <p>如您对奥飞特七中国游戏隐私政策有任何问题或疑虑，可以通过以下方式联系我们：</p>

        <ul>
          <li>
            关于隐私问题和行使您的权利，请联系privacy@outfit7networktech.com；
          </li>
          <li>
            关于常规问题，请联系support@outfit7networktech.com；
          </li>
          <li>
            我们的中国办公室：中国上海市徐汇区裕德路126号氪空间2130室。邮政编码：200030
          </li>
        </ul>

        <p>我们将尽快审核您的问题，并在核实您身份后的15个工作日内回复。</p>
        <p><strong>奥飞特七中国游戏隐私政策，2024年12月10日</strong></p>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'
import InternalLink from '@components/InternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,
    InternalLink
  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    strong {
      color: #000;
    }
    &.hscroll {
      overflow-x: scroll;
      max-width: 100vw;
      table, tr, td {
        border: 1px solid #000;
        vertical-align: baseline;
      }
      td {
        padding: 10px;
        min-width: 160px;
      }
    }
    .link {
      display: inline-block;
      text-decoration: underline;
      color: #6d8092;
      text-transform: uppercase;
      &:active, &:visited {
        text-decoration: underline;
        color: #6d8092;
        text-transform: uppercase;
      }
    }

    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
